@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.active {
  /*background-color: #6b6969 !important;*/
  background-color: #5086ac !important;
}

.sun-editor-editable {
  font-size: 18px !important;
  min-height: 11rem !important;
  font-family: 'Roboto', sans-serif !important;
}

.profile-div {
  font-family: 'Roboto', sans-serif;
}
/* .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
  border-radius: 50px;
  margin: 0px;
}

.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button:hover {
  border-radius: 50px;
  background-color: #5086ac;
  text-decoration: underline;
}
 */
